import { PickerFileMetadata } from 'filestack-js';
import { ComplexValueType, UserResponse } from '../../../components/Core/CoreTypes';

// {
//   "traceId": "HIss6a9f8oPamzsIxBTq7",
//   "requestId": "TEt78JOL23dF8K3hndlCG",
//
//   "responseData": {},
//
//   "serviceData": {
//     "processName": "tda",
//     "processPayload": {
//       "category": "creditUnion",
//       "accounts": [{
//           "accountId": "1",
//           "employedByCreditUnion": true
//         },
//         {
//           "accountId": "2",
//           "employedByCreditUnion": false
//         }
//       ]
//     }
//   }
// }

type DocumentUploadTDASchema = {
  documentUploadFileName: string,
  documentUploadFileUrl: string,
  documentUploadFileHandle: string
};

type PossibleTDAValues = string | number | boolean | DocumentUploadTDASchema | Record<string, ComplexValueType>;

type TDASignals = Record<string, PossibleTDAValues>;

interface AccountsType extends TDASignals {
  accountId: string
}

const hasValidTDAResponse = ( componentId: string, value: UserResponse['value'] | DocumentUploadTDASchema, translatorKeysOrController: string[] | string, componentType: string, canComplete: boolean ): boolean => {
  let firstArgument: boolean;
  if( typeof translatorKeysOrController === 'string' ) {
    // means its a controller
    firstArgument = componentId === translatorKeysOrController;
  } else {
    // translator keys
    firstArgument = translatorKeysOrController.indexOf(componentId) > -1;
  }

  // For button types (FabNav) falsy values are allowed, also canComplete not needed
  if(componentType === 'button') {
    return firstArgument;
  }
  return (firstArgument && !!value && !!canComplete);
};
 
const transformFilestackValue = (filestackMetaData: PickerFileMetadata[]): DocumentUploadTDASchema => {
  return(
    {
      documentUploadFileName: filestackMetaData[0].filename,
      documentUploadFileUrl: filestackMetaData[0].url,
      documentUploadFileHandle: filestackMetaData[0].handle
    }
  );
};

type SignalAccumulatorProps = {
  responses: UserResponse[],
  translatorKeys: string[],
  controller?: string,
  uploadCompletedSignal?: string,
  documentType?: string, 
  canComplete: boolean
};

type SignalAccumulatorOutput = {
  accumulatedSignals: Record<string, PossibleTDAValues>,
  preInitAccounts: AccountsType[],
  cardListTranslatorKey: string
};

const signalAccumulator  = ({ responses, translatorKeys, controller, uploadCompletedSignal, documentType, canComplete }: SignalAccumulatorProps ): SignalAccumulatorOutput => {
  let accumulatedSignals = {};
  let preInitAccounts: AccountsType[] = [];
  let cardListTranslatorKey= '';

  // iterate through responses
  responses.forEach((response: UserResponse) => {

    // Check if component is a CardList multi-select first and its uuid is inside a translatorKey
    // if so, create preInitAccounts w/ cardListTranslatorKey
    if (response.componentType === 'selectCardList' && translatorKeys.indexOf(response.componentId) !== -1) {

      cardListTranslatorKey = response.componentId;

      preInitAccounts = Object.keys(response.value as Record<string, ComplexValueType>).map((accountId: string) => {
        return {
          accountId,
          [cardListTranslatorKey]: (response.value as Record<string, ComplexValueType>)[accountId].value,
          ...( uploadCompletedSignal ? { [uploadCompletedSignal]: false } : {})
        };
      });
    } else {
      // process response.value
      let value: UserResponse['value'] | DocumentUploadTDASchema;

      if(response.componentType === 'filestackUploadList' && response.value) {
        value = transformFilestackValue(response.value as PickerFileMetadata[]);
      } else {
        value = (Array.isArray(response.value) && typeof response.value[0] === 'string') ? response.value[0] : response.value; 
      }

      // Check for value validity
      if (hasValidTDAResponse(response.componentId, value, controller || translatorKeys, response.componentType || 'button', canComplete) && value !== undefined && value !== null) { 
        
        // if !!controller all translatorKeys are accumulated (PS: only one response will pass the validator)
        if(controller) {
          translatorKeys.forEach((key) => {
            accumulatedSignals = {...accumulatedSignals, [key]: value };
          });
        } else {
        // if controller is not present process each signal individually

          // process extra signals if componentType is filestackUploadList
          let extraDocumentUploadSignals = {};
          if(response.componentType === 'filestackUploadList') {
            extraDocumentUploadSignals = {
              ...( uploadCompletedSignal ? { [uploadCompletedSignal]: true } : {} ),
              ...( documentType ? { documentType } : {} )
            };
          }
          
          accumulatedSignals = {
            ...accumulatedSignals,
            [response.componentId]: value,
            ...extraDocumentUploadSignals
          };
        }
      } 
    }
  });

  return { accumulatedSignals, preInitAccounts, cardListTranslatorKey};
};

type AccountArrayInitializerProps = {
  accountIdArray: string[],
  uploadCompletedSignal?: string
};

const accountArrayInitializer = ({ accountIdArray, uploadCompletedSignal }: AccountArrayInitializerProps ): AccountsType[] => {
  const accounts: AccountsType[] = accountIdArray.map((id) => ({ accountId: id, ...( uploadCompletedSignal ? { [uploadCompletedSignal]: false } : {}) }));
  return accounts;
};

type SignalAggregatorProps = {
  accumulatedSignals: Record<string, PossibleTDAValues>,
  initAccounts: AccountsType[],
  cardListTranslatorKey?: string
};

const signalAggregator = ({ accumulatedSignals, initAccounts, cardListTranslatorKey }: SignalAggregatorProps): AccountsType[] => {
  if (cardListTranslatorKey) {
    const aggregatedAccountsArray = initAccounts.map(account => {
      return {
        ...account,
        ...(account[cardListTranslatorKey] === true ? accumulatedSignals : {})
      };
    });
    return aggregatedAccountsArray;
  } 
  const aggregatedAccountsArray = initAccounts.map(account => {
    return {
      ...account,
      ...accumulatedSignals
    };
  });
  return aggregatedAccountsArray;
};

/**
 * 9:31
 * `category` is the type of account -
 * `creditUnion`, `medical`, `shortTermFixedDebt` etc.
 * ☝️ long term we likely want args, but we'll roll static for nwo
 */
const translateForTradelineAnalyzer = (
  category: string,
  translatorKeys: string[],
  responses: UserResponse[],
  canComplete: boolean = true,
  accountId: string | string[] = [],
  controller?: string,
  uploadCompletedSignal?: string,
  documentType?: string
) => {
  let initAccounts: AccountsType[] = [];
  const accountIdArray =
    typeof accountId === 'string' ? [accountId] : accountId;

  const {
    accumulatedSignals,
    preInitAccounts,
    cardListTranslatorKey
  } = signalAccumulator({
    responses,
    translatorKeys,
    controller,
    uploadCompletedSignal,
    documentType,
    canComplete
  });

  if (preInitAccounts.length > 0) {
    // accountArray was initialized in signalAccumulator (when response has a selectCardList component)
    initAccounts = preInitAccounts;
  } else {
    initAccounts = accountArrayInitializer({
      accountIdArray,
      uploadCompletedSignal
    });
  }

  const accounts: AccountsType[] = signalAggregator({
    accumulatedSignals,
    initAccounts,
    cardListTranslatorKey
  });

  return {
    serviceName: 'tradelineAnalyzer',
    servicePayload: {
      category,
      accounts
    }
  };
};

 type AccountListTranslatorData = {
   category: string,
   translatorKeys: string[],
 };

interface AccountListDisplayOnly extends AccountListTranslatorData {
  accountId: string[];
}

interface SingleAccountTranslatorData extends AccountListTranslatorData {
  accountId: string;
}

interface SingleAccountOneToManyTranslatorData extends SingleAccountTranslatorData {
  controller: string;
}

interface SingleAccountDocumentUploadTranslatorData extends SingleAccountTranslatorData {
  documentType: string;
  uploadCompletedSignal: string;
}

// TODO: this could be cleaned but would also need to change JSON
export const tradelineAnalyzerAccountList = (res: UserResponse[], translatorData: AccountListTranslatorData) =>
  translateForTradelineAnalyzer(translatorData.category, translatorData.translatorKeys, res);

export const tradelineAnalyzerAccountListPlus = (res: UserResponse[], translatorData: AccountListTranslatorData, canComplete: boolean) =>
  translateForTradelineAnalyzer(translatorData.category, translatorData.translatorKeys, res, canComplete);

export const tradelineAnalyzerAccountListDisplayOnly = (res: UserResponse[], translatorData: AccountListDisplayOnly, canComplete: boolean) =>
  translateForTradelineAnalyzer(translatorData.category, translatorData.translatorKeys, res, canComplete, translatorData.accountId);

export const tradelineAnalyzerSingleAccount = (res: UserResponse[], translatorData: SingleAccountTranslatorData, canComplete: boolean) =>
  translateForTradelineAnalyzer(translatorData.category, translatorData.translatorKeys, res, canComplete, translatorData.accountId);

export const tradelineAnalyzerSingleAccountOneToMany = (res: UserResponse[], translatorData: SingleAccountOneToManyTranslatorData, canComplete: boolean) =>
  translateForTradelineAnalyzer(translatorData.category, translatorData.translatorKeys, res, canComplete, translatorData.accountId, translatorData.controller);

export const tradelineAnalyzerSingleAccountDocumentUpload = (res: UserResponse[], translatorData: SingleAccountDocumentUploadTranslatorData, canComplete: boolean) =>
  translateForTradelineAnalyzer(translatorData.category, translatorData.translatorKeys, res, canComplete, translatorData.accountId, undefined, translatorData.uploadCompletedSignal, translatorData.documentType);
