import Cookies from 'universal-cookie';
import { encodeUrlSafeBase64 } from './urlSafeBase64';

type StepJsonHeader = {
  id: string;
  name: string;
  nodeTree: Object;
  protected?: boolean;
};

const AWS_REGION = process.env.REACT_APP_COGNITO_AWS_REGION;
const USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
const APP_CLIENT_ID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
const COOKIE_DOMAIN = process.env.REACT_APP_COGNITO_COOKIE_DOMAIN;
const RETURN_URL = process.env.REACT_APP_COGNITO_RETURN_URL;
const AUTH_SPA_BASE_URL = process.env.REACT_APP_COGNITO_AUTH_SPA_BASE_URL;

export const isAuthRequired = (stepJsonHeader: StepJsonHeader) => {
  const hasProtectedKey = Object.keys(stepJsonHeader).includes('protected');
  if (hasProtectedKey && stepJsonHeader.protected === true) return true;

  return false;
};

export const isAuthenticated = () => {
  const cookies = new Cookies();

  // Check for auth cookies, if missing not auth'ed.
  // A "sub" Cognito's internal user ID.
  const prefix = 'CognitoIdentityServiceProvider';
  const lastSub = cookies.get(`${prefix}.${APP_CLIENT_ID}.LastAuthUser`);
  const idToken = cookies.get(`${prefix}.${APP_CLIENT_ID}.${lastSub}.idToken`);
  if (!lastSub || !idToken) return false;

  // Verify JWT structure (middle is user info),
  // confirm expiration date is not in the past.
  const userDetails = JSON.parse(atob(idToken.split('.')[1]));
  if (Math.floor(Date.now() / 1000) > userDetails.exp) return false;

  // Is authenticated. 🎉
  return true;
};

const buildQueryState = () => {
  const state = {
    returnUrl: RETURN_URL,
    cookieDomain: COOKIE_DOMAIN,
    awsRegion: AWS_REGION,
    awsUserPoolId: USER_POOL_ID,
    awsUserPoolWebClientId: APP_CLIENT_ID,
    iat: Date.now()
  };

  return encodeUrlSafeBase64(JSON.stringify(state));
};

export const handleAuthCheck = (stepJsonHeader: StepJsonHeader) => {
  return new Promise((resolve, reject) => {
    if (isAuthRequired(stepJsonHeader) && !isAuthenticated()) {
      const qs = buildQueryState();
      window.location.href = `${AUTH_SPA_BASE_URL}/signin?qs=${qs}`;
      reject();
    }

    resolve(true);
  });
};
