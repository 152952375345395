export { yourcase } from './yourcase';
export { mrreset } from './mrreset';
export { kele } from './kele';
export { drs } from './drs';
export { ylfDemo } from './ylfDemo';

export const defaultTheme = {
  strings: {
    brand: '',
    brandUrl: '',
    errorToast: {
      default: {
        message: 'Looks like there was a connection error. Please try again.',
        buttonLabel: 'Try Again'
      },
      deadLock: {
        message:
          'Oops looks like something broke. Please visit us while we fix your experience.',
        buttonLabel: 'Visit us.'
      }
    }
  },
  styles: {}
};
