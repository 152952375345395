import React from 'react';
import {
  createMuiTheme,
  ThemeProvider,
  Theme,
  useTheme
} from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { yourcase, mrreset, kele, drs } from '../../styles/themes';
import { ComponentWithChildren } from '../../models/models';
import {
  ExperienceTheme,
  OverridesVariants
} from '../../styles/themes/ThemeTypes';

export interface Props extends ComponentWithChildren {
  /**
   * The component that will be replaced with its variant.
   */
  component?: string;
  /**
   * The variant that will replace the default overrides.
   */
  variant?: string;
  /**
   * Custom styles that will override the default styles of the specified component.
   */
  customStyles?: Record<string, any>;
  /**
   * Object of component: variant pairs in order to be able to replace multiple individual overrides.
   */
  multiVariants?: Record<string, string>;
  /**
   * Using a set of overrides for multiple components as opposed to a single component.
   */
  overridesBatch?: string;
  /**
   * The theme that will be used to reset to default.
   */
  themeToResetTo?: string;
}

type Themes = 'yourcase' | 'mrreset' | 'kele' | 'drs';

type ThemeMapInterface = Record<Themes, ExperienceTheme>;
const themeMap: ThemeMapInterface = {
  yourcase,
  mrreset,
  kele,
  drs
};

const VariantProvider: React.FC<Props> = ({
  component,
  variant,
  customStyles,
  overridesBatch,
  children,
  themeToResetTo,
  multiVariants
}) => {

  const currentTheme = useTheme();

  const variantTheme = React.useMemo(() => {
    if (themeToResetTo && themeToResetTo in themeMap) {
      return createMuiTheme(themeMap[themeToResetTo as keyof ThemeMapInterface].styles);
    }
    if (component && variant && currentTheme.overrides) {
      return createMuiTheme({
        ...currentTheme,
        overrides: {
          ...currentTheme.overrides,
          [`Mui-${component}`]: {
            ...currentTheme.overrides[`Mui-${component}` as keyof Overrides],
            ...currentTheme.overrides[`Mui-${variant}`  as keyof Overrides]
          }
        }
      });
    }
    if (component && customStyles && currentTheme.overrides) {
      return createMuiTheme({
        ...currentTheme,
        overrides: {
          ...currentTheme.overrides,
          [`Mui-${component}`]: {
            ...currentTheme.overrides[`Mui-${component}` as keyof Overrides],
            ...customStyles
          }
        }
      });
    }
    if (multiVariants && currentTheme.overrides) {
      let newOverrides = {};
      Object.keys(multiVariants).forEach((componentKey) => {
        if (currentTheme.overrides) {
          newOverrides = {
            ...newOverrides,
            [`Mui-${componentKey}`]: {
              ...currentTheme.overrides[`Mui-${componentKey}` as keyof Overrides],
              ...currentTheme.overrides[`Mui-${multiVariants[componentKey]}` as keyof Overrides]
            }
          };
        }
      });
      return createMuiTheme({
        ...currentTheme,
        overrides: {
          ...currentTheme.overrides,
          ...newOverrides
        }
      });
    }
    if (overridesBatch && currentTheme.overrides) {
      return createMuiTheme({
        ...currentTheme,
        overrides: {
          ...currentTheme.overrides,
          ...currentTheme[overridesBatch as keyof Theme] as OverridesVariants
        }
      });
    }    
    return createMuiTheme(currentTheme); // Do nothing option
  }, [component, variant, customStyles, overridesBatch, currentTheme, themeToResetTo, multiVariants]);

  return <ThemeProvider<Theme> theme={variantTheme}>{children}</ThemeProvider>;
};

export default VariantProvider;
